<template>
  <MerchantCategoryForm
    :category="category"
    @success="onSuccess"
    @cancel="onCancel"
  />
</template>

<script>
import { mapGetters } from "vuex";
import MerchantCategoryForm from "../../../components/categories/MerchantCategoryForm";
export default {
  components: { MerchantCategoryForm },
  data() {
    return {
      categoryId: this.$route.params.categoryId,
    };
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
      getCategory: "merchant/categories/getItem",
    }),
    category() {
      if (this.categoryId) {
        return this.getCategory(this.categoryId);
      }
      return null;
    },
  },
  methods: {
    onSuccess() {
      this.$router.replace({ name: "CategoriesList" });
    },
    onCancel() {
      this.$router.go(-1);
    },
  },
};
</script>